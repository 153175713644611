<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import "normalize.css";
export default {
  mounted() {
    document.title = '云组态'
  }
};
</script>

<style lang="stylus">
body {
  font: 12px Arial, "PingFang SC", "Hiragino Sans GB", STHeiti,
    "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

#app {
  width: 100%;
  height: 100%;
}

.blue { // 淡蓝色
  background-color #25ACFD
}
</style>
