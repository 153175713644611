import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  redirect: 'studio'
}, {
  path: '/studio',
  name: "studio",
  component: () =>
    import('../views/studio.vue')
}, {
  path: '/board',
  name: "board",
  component: () =>
    import('../views/board.vue')
}, {
  path: '/edit',
  name: "edit",
  component: () =>
    import('../views/edit.vue')
}]

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  // mode: 'history',
  routes
})

export default router