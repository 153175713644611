import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import MuseUI from 'muse-ui'; // 中文文档：https://muse-ui.org/#/zh-CN/installation
import 'muse-ui/dist/muse-ui.css';
import 'typeface-roboto'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
Vue.use(MuseUI);

/* - - - - -  */

import Toast from 'muse-ui-toast';
Vue.use(Toast, {
  position: 'top',
  time: 3000,
});

import Message from 'muse-ui-message';
Vue.use(Message);

import layer from 'vue-layer'
import 'vue-layer/lib/vue-layer.css';
Vue.prototype.$layer = layer(Vue);

Vue.config.productionTip = false;

/* - - - - -  */


/* - - - - -  */

import './assets/font.css';

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')